import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './StampDutyPage.module.scss';
import { Helmet } from 'react-helmet';
import { generatePDFDocumentFromRef } from '../../../util/generatePDF';
import Spinner from '../../../components/Spinner/Spinner';
import { GlobalContext } from '../../../context/globalContext';
import LanguageToggle from '../../../components/LanguageToggle/LanguageToggle';

/*
Test cases: All tested

A. UK buyer & first home
240000  0
250000  0
424999  0
425000  0
624999  9,999.95    1.6%
625000  10,000.00   1.6%    // table with relief, 0-425000
800000  27,500.00   3.4%
925000  33,750.00   3.6%
1000000 41,250.00   4.1%
1500000 91,250.00   6.1%
2000000 151,250.00  7.6%

B. UK buyer & second home
39999   0                   // table with relief, 0-39999
40000   1,200.00    3.0%
240000  7,200.00    3.0%
250000  7,500.00    3.0%
800000  51,500.00   6.4%
925000  61,500.00   6.6%
1000000 71,250.00   7.1%
1500000 136,250.00  9.1%
2000000 211,250.00  10.6%

C. non UK buyer & first home
240000  4,800.00    2.0%
250000  5,000.00    2.0%
424999  8,499.98    2.0%
425000  8,500.00    2.0%
624999  22,499.93   3.6%
625000  22,500.00   3.6%    // table with relief 0-425000
800000  43,500.00   5.4%
925000  52,250.00   5.6%
1000000 61,250.00   6.1%
1500000 121,250.00  8.1%
2000000 191,250.00  9.6%

C. non UK buyer & second home
39999   799.98      2.0%    // table with relief 39999
40000   2,000.00    5.0%
240000  12,000.00   5.0%
250000  12,500.00   5.0%
800000  67,500.00   8.4%
925000  80,000.00   8.6%
1000000 91,250.00   9.1%
1500000 166,250.00  11.1%
2000000 251,250.00  12.6%


 */


export default function StampDutyPage() {
    const [isFirstHome, setIsFirstHome] = useState(true);
    const [propertyPrice, setPropertyPrice] = useState(0);
    const [propertyPriceDisplay, setPropertyPriceDisplay] = useState('0');
    const [isNonUKResident, setIsNonUKResident] = useState(false);
    const [taxTable, setTaxTable] = useState({ status: "idle", data: [] });
    const [taxData, setTaxData] = useState({ status: "idle", data: { tax: 0, rate: 0 } });
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const { isSmallScreen } = useContext(GlobalContext);
    const tableRef = useRef(null);
    const [isEnglish, setIsEnglish] = useState(new URLSearchParams(window.location.search).get('language') === 'chinese' ? false : true);

    function handleCalculate(e) {
        e.preventDefault();
        let bands = [
            { lower: 0, upper: 250000, rate: 0.00 },
            { lower: 250000, upper: 925000, rate: 0.05 },
            { lower: 925000, upper: 1500000, rate: 0.10 },
            { lower: 1500000, upper: Infinity, rate: 0.12 }
        ];

        // Surcharge rates
        const nonUKResidentSurcharge = isNonUKResident ? 0.02 : 0;
        const additionalPropertySurcharge = (!isFirstHome && +propertyPrice >= 40000) ? 0.03 : 0;

        // Adjust for first-time buyer exemption
        if (isFirstHome && +propertyPrice <= 625000) {
            bands[0].upper = 425000; // No SDLT on the first £425,000
            bands[1].lower = 425000;
            bands[1].upper = 625000; // Reduced rates up to £625,000
        }

        if (!isFirstHome && +propertyPrice < 40000) {
            bands = [
                { lower: 0, upper: 39999, rate: 0.00 },
            ];
        }

        let tax = 0;
        let tableData = [];
        let price = +propertyPrice;

        bands.forEach(band => {
            if (price > band.lower) {
                let effectiveRate = band.rate + nonUKResidentSurcharge + additionalPropertySurcharge;
                const taxableSum = Math.min(price, band.upper) - band.lower;
                const taxForBand = taxableSum * effectiveRate;
                tax += taxForBand;

                let taxBandText = band.upper === Infinity ? "£1,500,000+" : `£${band.lower.toLocaleString()} - £${band.upper.toLocaleString()}`;

                tableData.push({
                    taxBand: taxBandText,
                    rate: `${(effectiveRate * 100).toFixed(0)}%`,
                    taxableSum: `£${taxableSum.toLocaleString()}`,
                    tax: `£${taxForBand.toFixed(2)}`
                });
            }
        });



        setTaxTable({ status: "success", data: tableData });
        setTaxData({
            status: "success", data: {
                rate: tax ? parseFloat((tax / price * 100).toFixed(1)) : 0,
                tax: +tax.toFixed(2),
            }
        });
    }

    const handleChange = (e) => {
        setIsFirstHome(e.target.value === "first");
    };


    function handlePropertyPriceInput(e) {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');
        const numericValue = parseInt(inputValue, 10);

        if (!isNaN(numericValue)) {
            setPropertyPrice(numericValue);
            setPropertyPriceDisplay(numericValue.toLocaleString());
        } else {
            setPropertyPrice(0);
            setPropertyPriceDisplay('0');
        }
    }

    function handleSaveThisCalculation() {

    }

    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('language', isEnglish ? 'english' : 'chinese');
        window.history.pushState({}, '', url);
    }, [isEnglish]);

    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                const doc = await generatePDFDocumentFromRef(tableRef.current);
                if (doc) {
                    doc.save(`Stamp Duty Calculation.pdf`);
                    // const pdfBlob = doc.output('blob');
                    // const pdfUrl = URL.createObjectURL(pdfBlob);
                    // setPdfUrl(pdfUrl);

                    // !isSmallScreen && window.open(pdfUrl, '_blank');
                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF, tableRef.current]);

    return (
        <>
            <Helmet>
                <title>Stamp Duty Calculator | Letting Engine</title>
                <meta
                    name="description"
                    content="Use our stamp duty calculator, Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals."
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, stamp duty calculator, stamp duty"
                />
            </Helmet>

            <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} />
            <div className={`${styles.stampDutyPage} content-inner p-6 sm:p-16 font-main-sans max-w-[960px] mx-auto ${isEnglish ? "isEnglish" : "isChinese"}`}>
                <h1 className='font-semibold mb-8 text-4xl'>
                    <span className='english'>Stamp Duty Land Tax (SDLT)<br /> Information and Calculator 2023/2024</span>
                    <span className='chinese'>印花税土地税 (SDLT)<br /> 信息和计算器 2023/2024</span>
                </h1>

                <p className='font-main-sans font-light text-base'>
                    <span className='english'>Stamp Duty Land Tax (SDLT) is a tax paid on property or land purchases in England and Northern Ireland. The tax varies if the property is in Scotland (Land and Buildings Transaction Tax) or Wales (Land Transaction Tax). SDLT applies when you buy a freehold property, a new or existing leasehold, a property through a shared ownership scheme, or when land or property is transferred in exchange for payment.</span>
                    <span className='chinese'>印花税土地税 (SDLT) 是在英格兰和北爱尔兰购买房产或土地时支付的税款。如果房产位于苏格兰（土地和建筑物交易税）或威尔士（土地交易税），税额会有所不同。当您购买不动产、新的或现有的租赁权、通过共有权方案购买的房产，或者在交换付款时转让土地或房产时，需要支付 SDLT。</span>
                </p>

                <div ref={tableRef} className='mt-12 to-print-stamp-duty'>
                    <h2>
                        <span className='english'>Calculate your Stamp Duty</span>
                        <span className='chinese'>计算您的印花税</span>
                    </h2>
                    <h3>
                        <span className='english'>Your Information</span>
                        <span className='chinese'>您的信息</span>
                    </h3>
                    <form onSubmit={handleCalculate} className={`${styles.calculateForm} flex flex-col gap-3`} onChange={() => { setTaxData({ status: "idle", data: { tax: 0, rate: 0 } }); setTaxTable({ status: "idle", data: [] }); setPdfUrl(""); }}>
                        <label className='hideSpinner flex items-center gap-3'>
                            <span className='english'>Property Price</span>
                            <span className='chinese'>房产价格</span>
                            <input className="input input-bordered input-warning input-sm w-full max-w-xs bg-transparent" type='text' value={propertyPriceDisplay} onChange={handlePropertyPriceInput} />
                        </label>
                        <label className='flex items-center gap-3'>
                            <input
                                className='checkbox checkbox-warning'
                                type="checkbox" checked={isNonUKResident} onChange={(e) => { setIsNonUKResident(e.target.checked); }} />
                            <span className='english'>The buyer is a non-UK resident</span>
                            <span className='chinese'>买家是非英国居民</span>
                        </label>
                        <div className='flex gap-6'>
                            <label className='flex items-center gap-3'>
                                <input
                                    className='radio radio-warning radio-sm'
                                    type="radio"
                                    value="first"
                                    checked={isFirstHome}
                                    onChange={handleChange}
                                />
                                <span className='english'>Property is a first home</span>
                                <span className='chinese'>房产是第一套住房</span>
                            </label>
                            <label className='flex items-center gap-3'>
                                <input
                                    className='radio radio-warning radio-sm'
                                    type="radio"
                                    value="second"
                                    checked={!isFirstHome}
                                    onChange={handleChange}
                                />
                                <span className='english'>Property is a buy to let or second home</span>
                                <span className='chinese'>房产是出租或第二套房</span>
                            </label>
                        </div>
                        {!showResult(taxData, taxTable) && <button className="btn btn-warning btn-sm w-32 mt-6" type="submit">
                            <span className='english'>Calculate</span>
                            <span className='chinese'>计算</span>
                        </button>}
                    </form>
                    {showResult(taxData, taxTable) && (
                        <>
                            <div className='w-full overflow-scroll'>
                                <h3>
                                    <span className='english'>Results</span>
                                    <span className='chinese'>结果</span>
                                </h3>
                                <table className='table w-full'>
                                    <thead>
                                        <tr className='text-base'>
                                            <th>
                                                <span className='english'>Stamp Duty to pay:</span>
                                                <span className='chinese'>应缴印花税：</span>
                                            </th>
                                            <th>
                                                <span className='english'>Effective Rate:</span>
                                                <span className='chinese'>有效税率：</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='font-semibold text-xl text-secondary-dark opacity-80'>
                                            <td>£{taxData.data.tax.toLocaleString()}</td>
                                            <td>{taxData.data.rate}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h3 className='text-3xl'>
                                    <span className='english'>Stamp Duty Calculation Breakdown</span>
                                    <span className='chinese'>印花税计算细则</span>
                                </h3>
                                <table className='table w-full'>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className='english'>Tax Band</span>
                                                <span className='chinese'>税率区间</span>
                                            </th>
                                            <th>%</th>
                                            <th>
                                                <span className='english'>Taxable Sum</span>
                                                <span className='chinese'>应税金额</span>
                                            </th>
                                            <th>
                                                <span className='english'>Tax</span>
                                                <span className='chinese'>税额</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxTable.data.map(row => {
                                            return (
                                                <tr>
                                                    <td>{row.taxBand}</td>
                                                    <td>{row.rate}</td>
                                                    <td>{row.taxableSum}</td>
                                                    <td>{row.tax}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className='mt-20 mb-20 opacity-50'>
                                    <p>{isEnglish ? "The amounts in the table are for reference only and are subject to actual occurrence;" : "表中的金额以实际发生为准，以上数据仅供参考；"}</p>
                                    <p>{isEnglish ? "If you reside continuously for 183 days after coming to the UK, you may be eligible for a 2% tax refund for overseas buyers with a visa that allows continuous residence." : "来英后连续居住183天可以退2%海外买家税，但需要购房者有可以连续居住的签证。"}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {showResult(taxData, taxTable) &&
                    <div className='flex flex-col items-center'>
                        <button className="btn btn-warning btn-sm m-0 mt-12" onClick={handleDownloadPDF}>
                            {isGeneratingPDF ?
                                <>
                                    <span className='english'>Downloading PDF...</span>
                                    <span className='chinese'>PDF 下载中 ...</span>
                                </>
                                :
                                <>
                                    <span className='english'>Download PDF</span>
                                    <span className='chinese'>下载 PDF</span>
                                </>
                            }
                        </button>
                        {isSmallScreen && pdfUrl && (
                            <div className="pdf-download-link">
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    <span className='english'>Open PDF</span>
                                    <span className='chinese'>打开 PDF</span>
                                </a>
                            </div>
                        )}
                    </div>
                }

                <div className='mt-36'>

                    <h2>
                        <span className='english'>Stamp Duty Rates</span>
                        <span className='chinese'>印花税税率</span>
                    </h2>
                    <p>
                        <span className='english'>Residential Properties</span>
                        <span className='chinese'>住宅物业</span>
                    </p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Up to £250,000</td>
                                <td>0%</td>
                            </tr>
                            <tr>
                                <td>£250,001 to £925,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td className='flex'>£925,001 to £1.5 <span className='english'>million</span><span className='chinese'>百万</span></td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td className='flex'>Above £1.5 <span className='english'>million</span><span className='chinese'>百万</span></td>
                                <td>12%</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>
                        <span className='english'>First-Time Buyers</span>
                        <span className='chinese'>首次购房者</span>
                    </h4>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Up to £425,000</td>
                                <td>0%</td>
                            </tr>
                            <tr>
                                <td>£425,001 to £625,000</td>
                                <td>5%</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <span className='english'>First-time buyer relief is not available for properties over £625,000</span>
                        <span className='chinese'>首次购房者减免不适用于超过 £625,000 的物业</span>
                    </p>

                    <h4>
                        <span className='english'>Additional Properties</span>
                        <span className='chinese'>额外物业</span>
                    </h4>
                    <p>
                        <span className='english'>An additional 3% on top of standard SDLT rates for buying new residential properties if you own more than one. Properties under £40,000 are not subject to the additional 3% tax.</span>
                        <span className='chinese'>如果您拥有多于一处物业，购买新住宅物业的标准 SDLT 税率上将增加额外的 3%。价值 £40,000 以下的物业不需缴纳额外的 3% 税。</span>
                    </p>

                    <h4>
                        <span className='english'>Non-UK Residents</span>
                        <span className='chinese'>非英国居民</span>
                    </h4>
                    <p>
                        <span className='english'>A 2% surcharge for non-UK residents buying residential properties</span>
                        <span className='chinese'>非英国居民购买住宅物业需缴纳 2% 的附加费</span>
                    </p>

                    <h2>
                        <span className='english'>SDLT Thresholds</span>
                        <span className='chinese'>SDLT 门槛</span>
                    </h2>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <span className='english'>Standard Residential Properties:</span>
                                    <span className='chinese'>标准住宅物业：</span>
                                </td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='english'>First-Time Buyers:</span>
                                    <span className='chinese'>首次购房者：</span>
                                </td>
                                <td>
                                    <span className='english'>£425,000 (for properties worth £625,000 or less)</span>
                                    <span className='chinese'>£425,000（适用于价值 £625,000 或以下的物业）</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>
                        <span className='english'>Eligibility and Reliefs</span>
                        <span className='chinese'>资格和减免</span>
                    </h2>
                    <p>
                        <span className='english'>Various SDLT reliefs available for first-time buyers, multiple dwellings, and in specific situations like property transfers due to divorce or for charitable purposes.</span>
                        <span className='chinese'>首次购房者、多套住宅以及特定情况（如因离婚或慈善目的的房产转让）可享受各种 SDLT 减免。</span>
                    </p>

                    <h2>
                        <span className='english'>Calculation of SDLT</span>
                        <span className='chinese'>SDLT 的计算</span>
                    </h2>
                    <p>
                        <span className='english'>The total value you pay SDLT on (the 'consideration') usually includes the price paid for the property or land and can sometimes include other types of payments like goods, works or services, or the release from a debt.</span>
                        <span className='chinese'>您支付 SDLT 的总价值（'考虑因素'）通常包括为房产或土地支付的价格，有时还可能包括货物、工程或服务的其他类型支付，或债务的解除。</span>
                    </p>

                    <h2>
                        <span className='english'>Payment and Deadlines</span>
                        <span className='chinese'>支付和截止日期</span>
                    </h2>
                    <p>
                        <span className='english'>SDLT should be filed and paid within 14 days of completion of the property purchase.</span>
                        <span className='chinese'>应在房产购买完成后 14 天内提交并支付 SDLT。</span>
                    </p>

                    <h2>
                        <span className='english'>Exemptions</span>
                        <span className='chinese'>免税</span>
                    </h2>
                    <p>
                        <span className='english'>Exemptions from SDLT or filing a return include property transfers with no payment exchange, inheritances, transfers due to divorce, properties under a certain value, and properties bought under alternative financial arrangements like Sharia law.</span>
                        <span className='chinese'>免除 SDLT 或提交退货的情况包括无支付交换的财产转让、继承、离婚转让、某一价值以下的财产，以及根据伊斯兰教法等替代金融安排购买的财产。</span>
                    </p>

                    <h2>
                        <span className='english'>Exemptions</span>
                        <span className='chinese'>豁免</span>
                    </h2>
                    <p>
                        <span className='english'>Exemptions from SDLT or filing a return include property transfers with no payment exchange, inheritances, transfers due to divorce, properties under a certain value, and properties bought under alternative financial arrangements like Sharia law.</span>
                        <span className='chinese'>免除 SDLT 或提交退货的情况包括无支付交换的财产转让、继承、离婚转让、某一价值以下的财产，以及根据伊斯兰教法等替代金融安排购买的财产。</span>
                    </p>

                    <h2>
                        <span className='english'>Additional Information</span>
                        <span className='chinese'>附加信息</span>
                    </h2>
                    <p>
                        <span className='english'>This calculator is for general interest and should not replace financial advice.</span>
                        <span className='chinese'>此计算器仅供一般参考，不应替代财务咨询。</span>
                    </p>
                    <p>
                        <span className='english'>Stamp duty can be added to mortgage loans, depending on the lender's terms.</span>
                        <span className='chinese'>根据贷款人的条款，印花税可以加到抵押贷款上。</span>
                    </p>
                </div>
            </div>
        </>

    );
}

function showResult(taxData, taxTable) {
    return taxData.status === "success" && taxTable.status === "success";
    // return taxData.tax !== 0 && taxTable && taxTable.length > 0;
}


// import React, { useState } from 'react';
// import './StampDutyPage.scss';
// import { Helmet } from 'react-helmet';

// export default function StampDutyPage() {
//     const [isFirstHome, setIsFirstHome] = useState('first');
//     const [propertyPrice, setPropertyPrice] = useState();
//     const [isNonUKResident, setIsNonUIResident] = useState();
//     const [taxTable, setTaxTable] = useState([]);
//     const [taxData, setTaxData] = useState({ tax: null, rate: null });


//     function handleCalculate(e) {
//         e.preventDefault();
//         const bands = [
//             { lower: 0, upper: 250000, rate: 0.00 },
//             { lower: 250000, upper: 925000, rate: 0.05 },
//             { lower: 925000, upper: 1500000, rate: 0.10 },
//             { lower: 1500000, upper: Infinity, rate: 0.12 }
//         ];

//         // Surcharge rates
//         const nonUKResidentSurcharge = isNonUKResident ? 0.02 : 0;
//         const additionalPropertySurcharge = isFirstHome === "first" ? 0 : 0.03;

//         // Adjust for first-time buyer exemption
//         if (isFirstHome === "first" && propertyPrice <= 625000) {
//             bands[0].upper = 425000; // No SDLT on the first £425,000
//             bands[1].lower = 425000;
//             bands[1].upper = 625000; // Reduced rates up to £625,000
//         }

//         let tax = 0;
//         let tableData = [];
//         let price = +propertyPrice;

//         bands.forEach(band => {
//             if (price > band.lower) {
//                 let effectiveRate = band.rate + nonUKResidentSurcharge + additionalPropertySurcharge;
//                 const taxableSum = Math.min(price, band.upper) - band.lower;
//                 const taxForBand = taxableSum * effectiveRate;
//                 tax += taxForBand;

//                 let taxBandText = band.upper === Infinity ? "£1,500,000+" : `£${band.lower.toLocaleString()} - £${band.upper.toLocaleString()}`;

//                 tableData.push({
//                     taxBand: taxBandText,
//                     rate: `${(effectiveRate * 100).toFixed(0)}%`,
//                     taxableSum: `£${taxableSum.toLocaleString()}`,
//                     tax: `£${taxForBand.toFixed(2)}`
//                 });
//             }
//         });

//         setTaxTable(tableData);
//         setTaxData({
//             rate: tax ? (tax / price * 100).toFixed(1) : 0,
//             tax: numberFormatter.format(tax.toFixed(2)), rate: tax ? (tax / price * 100).toFixed(1) : 0
//         });
//     }

//     const numberFormatter = new Intl.NumberFormat('en-US', {
//         style: 'decimal',
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//     });

//     const handleChange = (event) => {
//         setIsFirstHome(event.target.value);
//     };

//     function handlePropertyPriceInput(e) {
//         let inputValue = e.target.value;

//         // Remove non-numeric characters except commas
//         inputValue = inputValue.replace(/[^0-9,]/g, '');

//         // Convert to number and format with commas
//         const numericValue = parseInt(inputValue.replace(/,/g, ''), 10);

//         // Update the state with the formatted value
//         if (!isNaN(numericValue)) {
//             setPropertyPrice(numericValue.toLocaleString());
//         } else {
//             setPropertyPrice('');
//         }
//     }


//     return (
//         <>
//             <Helmet>
//                 <title>Stamp Duty Calculator 2023/2024 | Letting Engine</title>
//                 <meta name='description' content='Stamp Duty Land Tax (SDLT) Information and Calculator 2023/2024' />
//                 <meta name='keywords' content='stamp duty, stamp duty Land Tax, SDLT, calculator, 2023/2024' />
//             </Helmet>
//             <div className={"stamp-duty-page p-t-header"}>
//                 <h1>Stamp Duty Land Tax (SDLT) Information and Calculator 2023/2024</h1>

//                 <p>Stamp Duty Land Tax (SDLT) is a tax paid on property or land purchases in England and Northern Ireland. The tax varies if the property is in Scotland (Land and Buildings Transaction Tax) or Wales (Land Transaction Tax). SDLT applies when you buy a freehold property, a new or existing leasehold, a property through a shared ownership scheme, or when land or property is transferred in exchange for payment.</p>

//                 <div>
//                     <h2>Calculate your Stamp Duty</h2>
//                     <form onSubmit={handleCalculate} className='calculate-form'>
//                         <label className='hide-spinner'>
//                             <span>Property Price</span>
//                             <input type='text' value={propertyPrice} onChange={handlePropertyPriceInput} />
//                         </label>
//                         <label>
//                             <input type="checkbox" value={isNonUKResident} onChange={(e) => { setIsNonUIResident(e.target.checked); }} />
//                             <span>The buyer is a non-UK resident</span>
//                         </label>
//                         <div>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     value="first"
//                                     checked={isFirstHome === 'first'}
//                                     onChange={handleChange}
//                                 />
//                                 Property is a first home
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     value="second"
//                                     checked={isFirstHome === 'second'}
//                                     onChange={handleChange}
//                                 />
//                                 Property is a buy to let or second home
//                             </label>
//                         </div>
//                         <button type="submit">Calculate</button>
//                     </form>
//                     {taxData.tax && (
//                         <>
//                             <h2>Results</h2>
//                             <div>
//                                 <p>Study Duty to pay:</p>
//                                 <p>£{taxData.tax}</p>
//                             </div>
//                             <div>
//                                 <p>Effective Rate:</p>
//                                 <p>{taxData.rate}%</p>
//                             </div>
//                         </>
//                     )}
//                     <h2>Stamp Duty Calculation Breakdown</h2>
//                     <table>
//                         <thead>
//                             <tr>
//                                 <th>Tax Band</th>
//                                 <th>%</th>
//                                 <th>Taxable Sum</th>
//                                 <th>Tax</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {taxTable.length > 0 && taxTable.map(row => {
//                                 return (
//                                     <tr>
//                                         <td>{row.taxBand}</td>
//                                         <td>{row.rate}</td>
//                                         <td>{row.taxableSum}</td>
//                                         <td>{row.tax}</td>
//                                     </tr>
//                                 );
//                             })}
//                         </tbody>
//                     </table>
//                 </div>


//                 <h2>Stamp Duty Rates</h2>
//                 <p>Residential Properties</p>
//                 <table>
//                     <tbody>
//                         <tr><td>Up to £250,000</td><td>0%</td></tr>
//                         <tr><td>£250,001 to £925,000</td><td>5%</td></tr>
//                         <tr><td>£925,001 to £1.5 million</td><td>10%</td></tr>
//                         <tr><td>Above £1.5 million</td><td>12%</td></tr>
//                     </tbody>
//                 </table>

//                 <h3>First-Time Buyers</h3>
//                 <table>
//                     <tbody>
//                         <tr><td>Up to £425,000</td><td>0%</td></tr>
//                         <tr><td>£425,001 to £625,000</td><td>5%</td></tr>
//                     </tbody>
//                 </table>
//                 <p>First-time buyer relief is not available for properties over £625,000</p>

//                 <h3>Additional Properties</h3>
//                 <p>An additional 3% on top of standard SDLT rates for buying new residential properties if you own more than one</p>

//                 <h3>Non-UK Residents</h3>
//                 <p>A 2% surcharge for non-UK residents buying residential properties</p>

//                 <h2>SDLT Thresholds</h2>
//                 <table>
//                     <tbody>
//                         <tr><td>Standard Residential Properties:</td><td>£250,000</td></tr>
//                         <tr><td>First-Time Buyers:</td><td>£425,000 (for properties worth £625,000 or less)</td></tr>
//                     </tbody>
//                 </table>

//                 <h2>Eligibility and Reliefs</h2>
//                 <p>Various SDLT reliefs available for first-time buyers, multiple dwellings, and in specific situations like property transfers due to divorce or for charitable purposes.</p>

//                 <h2>Calculation of SDLT</h2>
//                 <p>The total value you pay SDLT on (the 'consideration') usually includes the price paid for the property or land and can sometimes include other types of payments like goods, works or services, or the release from a debt.</p>

//                 <h2>Payment and Deadlines</h2>
//                 <p>SDLT should be filed and paid within 14 days of completion of the property purchase.</p>

//                 <h2>Exemptions</h2>
//                 <p>Exemptions from SDLT or filing a return include property transfers with no payment exchange, inheritances, transfers due to divorce, properties under a certain value, and properties bought under alternative financial arrangements like Sharia law.</p>

//                 <h2>Additional Information</h2>
//                 <p>This calculator is for general interest and should not replace financial advice.</p>
//                 <p>Stamp duty can be added to mortgage loans, depending on the lender's terms.</p>
//             </div>
//         </>
//     );
// }